import { FC, useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Grid, Link, Modal, TextField, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ModalForProposeTimeSlot from '../Applications/ModalForProposeTimeSlot';
import API from "../../api/API";
import STRINGS from '../../utils/Strings';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


interface InterviewDetailProps {
    interviewData?: any;
    interviewSubmissionID?: any;
    candidateName?: any;
    candidateEmail?: any;
    setAvailabilities?: any;
    initialSlots?: any;
    slotId?: any;
    resumeVLNStatus?: any;
    updateDataAgain?: any;
    setUpdateDataAgain?: any;
    modifyMode?: any;
    description?: any;
    isTesting?: any
}

const InterviewDetail: FC<InterviewDetailProps> = (props) => {
    const [notes, setNotes] = useState("");
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [currentAvailabilities, setCurrentAvailabilities] = useState<any[]>([]);
    const [createdDate, setCreatedDate] = useState<string | null>(null);

    useEffect(() => {
        if (props.isTesting) {
            fetchInterviewData();
        }
    }, []);

    useEffect(() => {
        fetchInterviewData();
    }, [props.interviewSubmissionID]);

    const fetchInterviewData = async () => {
        const data = await API.getInterviewAvailabilitiesById(props.interviewSubmissionID);
        setCurrentAvailabilities(data?.entityList[0]?.currentAvailabilities || []);
        setNotes(data?.entityList[0]?.description || '');

        const createdDateRaw = data?.entityList[0]?.createdDate;
        const formattedDate = new Date(createdDateRaw).toLocaleDateString('en-US', {
            month: 'short', day: 'numeric', year: 'numeric',
        });
        setCreatedDate(formattedDate);
    };


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenEdit = (slot: any) => {
        setOpenEdit(true);
    };

    const getTruncatedText = (text: any) => {
        const maxChars = 185;
        if (text?.length > maxChars) {
            return `${text.slice(0, maxChars)}...`;
        }
        return text;
    };

    useEffect(() => {
        if (props.initialSlots) {
            setCurrentAvailabilities(props.initialSlots);
        }
    }, [props.initialSlots]);

    const getSlotBackground = (slot: any) => slot
        ? '#F8F8F8'
        : `repeating-linear-gradient(
        -45deg, 
        ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_COLOR}, 
        ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_COLOR} 3px, 
        ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_STRIPE_COLOR} 3px, 
        ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_STRIPE_COLOR} 5px)`;

    const getSlotBorder = (slot: any) => slot && slot.isFinalized
        ? '1px solid #4A57A3'
        : '1px solid #E3E3E3';

    const getSlotOpacity = (isPastDate: any) => isPastDate ? 0.5 : 1;

    const renderSlotCard = () => {
        const slotsToDisplay = [...currentAvailabilities];

        while (slotsToDisplay.length < 3) {
            slotsToDisplay.push(null);
        }

        return (
            <>
                {slotsToDisplay.map((slot, index) => {
                    const startTimeFormatted = slot
                        ? `${slot.interviewTime.slice(0, 5)}`
                        : "00:00";

                    const isPastDate = slot && new Date(slot.interviewDate) < new Date();
                    const isFinalized = slot && slot.isFinalized;

                    const key = slot ? slot.id : `tooltip-slot-${Math.random()}`;

                    const cardContent = (
                        <Card key={key} sx={{
                            flexBasis: "16%",
                            boxShadow: "none",
                            background: getSlotBackground(slot),
                            '& .css-hzek7d-MuiCardContent-root:last-child': {
                                paddingBottom: 0
                            },
                            border: getSlotBorder(slot),
                            opacity: getSlotOpacity(isPastDate),
                        }}>
                            <CardContent sx={{
                                height: 90, border: "1px solid #E3E3E3", borderRadius: "1px", padding: "5px", width: "100%", color: slot && slot.isFinalized ? "#4A57A3" : "#353535"
                            }}>
                                <Grid>
                                    {slot ? (
                                        <>
                                            <Typography variant='subtitle2' sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 16 }}>
                                                {new Date(slot.interviewDate).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' })}
                                            </Typography>
                                            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                                                <Grid sx={{ flexBasis: "50%" }}>
                                                    <Typography variant="subtitle2" sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 12 }}>Start time</Typography>
                                                    <Typography variant="subtitle2" sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 14 }}>
                                                        {startTimeFormatted}
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ flexBasis: "50%" }}>
                                                    <Typography variant="subtitle2" sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 12 }}>End time</Typography>
                                                    <Typography variant="subtitle2" sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 14 }}>
                                                        {calculateEndTime(startTimeFormatted, slot.interviewDuration)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography variant="subtitle2" sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 12 }}>Duration: {slot.interviewDuration}</Typography>
                                        </>
                                    ) : (
                                        <Typography variant='subtitle2' sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 16, color: '#AAA' }}></Typography>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                    );

                    return isFinalized ? (
                        <Tooltip key={key} title="Scheduled Interview Slot" placement="top" arrow>
                            {cardContent}
                        </Tooltip>
                    ) : (
                        cardContent
                    );
                })}

                <Card sx={{
                    flexBasis: "42%",
                    boxShadow: "none",
                    '& .MuiCardContent-root:last-child': {
                        paddingBottom: '0 !important',
                    }
                }}>
                    <CardContent
                        sx={{
                            height: 90,
                            borderRadius: "1px",
                            padding: "0px",
                            overflow: "hidden",
                            position: "relative",
                            display: "flex"
                        }}
                    >
                        <TextField
                            multiline
                            fullWidth
                            value={getTruncatedText(props.description)}
                            variant="outlined"
                            InputProps={{
                                readOnly: !openEdit,
                                sx: {
                                    padding: 0,
                                    height: "90px",
                                    border: `1px solid ${STRINGS.PROPOSE_TIME_SLOTS.SLOT_BORDER_COLOR} 1px`,
                                    backgroundColor: STRINGS.PROPOSE_TIME_SLOTS.SLOT_CONTAINER_COLOR,
                                    '& .MuiOutlinedInput-root': {
                                        padding: "8px",
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        lineHeight: '1.5em',
                                        maxHeight: '4.5em',
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    padding: "8px",
                                    borderRadius: "1px",
                                },
                                '& .MuiInputBase-input': {
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                },
                            }}
                        />
                        {notes.length > 100 && (
                            <Link
                                onClick={handleOpen}
                                sx={{
                                    position: "absolute",
                                    bottom: '5px',
                                    right: '5px',
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    marginRight: "300px",
                                    marginBottom: "10px"
                                }}
                            >
                                Read More
                            </Link>
                        )}
                        <EditOutlinedIcon
                            onClick={() => handleOpenEdit({
                                slot: null,
                                notes: notes,
                            })}
                            sx={{ alignSelf: "center", width: "60px", color: "#4A57A3", fontSize: 35 }}
                        />
                    </CardContent>
                </Card>
            </>
        );
    };



    const calculateEndTime = (startTime: any, duration: any) => {
        let [hours, minutes] = startTime.split(':').map(Number);

        let durationHours = 0;
        let durationMinutes = 0;

        const hourMatch = duration.match(/(\d+)h/);
        const minuteMatch = duration.match(/(\d+)m/);

        if (hourMatch) {
            durationHours = parseInt(hourMatch[1]);
        }
        if (minuteMatch) {
            durationMinutes = parseInt(minuteMatch[1]);
        }

        hours += durationHours;
        minutes += durationMinutes;

        if (minutes >= 60) {
            hours += Math.floor(minutes / 60);
            minutes = minutes % 60;
        }

        const endTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        return endTime;
    };



    return (
        <Box
            data-testid='interview-details'
        >
            <Grid
                sx={{
                    border: "1px solid #E3E3E3",
                    borderRadius: "6px",
                }}
            >
                <Typography variant='subtitle2' sx={{ margin: "20px", fontFamily: "Roboto", fontWeight: 700, fontSize: 18 }}>
                    Proposed Interview Slots  | {createdDate}
                    <Tooltip title="Interview Slot Proposal Date" placement="top" arrow>
                        <InfoOutlinedIcon sx={{ marginLeft: "6px", verticalAlign: "middle", fontSize: 20, cursor: "pointer", color: "#5F6368" }} />
                    </Tooltip>
                </Typography>
                <Grid sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "16px",
                    flexWrap: "wrap",
                    '@media (max-width: 600px)': {
                        flexDirection: "column",
                        gap: "8px",
                    },
                    margin: "20px"
                }}>
                    {renderSlotCard()}
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{ bgcolor: 'background.paper', padding: 4, borderRadius: 2, maxWidth: 500, width: '100%' }}>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>Notes</Typography>
                    <Typography variant="body1" sx={{ marginBottom: 4 }}>{notes}</Typography>
                    <Button onClick={handleClose} variant="contained" sx={{ display: 'block', marginLeft: 'auto' }}>Close</Button>
                </Box>
            </Modal>

            <ModalForProposeTimeSlot
                scheduleInterviewOpen={openEdit}
                setScheduleInterviewOpen={setOpenEdit}
                setAvailabilities={props.setAvailabilities}
                candidateName={props.candidateName}
                candidateEmail={props.candidateEmail}
                slotId={props.slotId}
                availabilities={props.initialSlots}
                description={props.description}
                modifyMode={props.modifyMode}
                resumeVLNStatus={props.resumeVLNStatus}
                id={props.interviewSubmissionID}
                updateDataAgain={props.updateDataAgain}
                setUpdateDataAgain={props.setUpdateDataAgain}
            />
        </Box>
    )
}

export default InterviewDetail
