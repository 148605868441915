// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import {
  Box,
  IconButton,
  InputAdornment,
  Modal,
  Popover,
  Switch,
  TextField,
} from "@mui/material";
import { IoMdSearch } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";
import { Oval } from "react-loader-spinner";

// Functional Imports
import Colors from "../../utils/Colors";
import ENUMS from "../../utils/Enums";
import API from "../../api/API";

// Component Imports
import ButtonOutlined from "../../common/ButtonOutlined";
import CheckboxLabel from "../../common/CheckboxLabel";
import ButtonFilled from "../../common/ButtonFilled";
import FilterName from "../../common/FilterName";
import Text from "../../common/Text";
import Functions from "../../utils/Functions";

interface FiltersPopupProps {
  isExpanded: boolean;
  setIsExpanded?: any;
  selectedFilter?: any;
  setSelectedFilter?: any;
  searchText?: any;
  setSearchText?: any;
  dataLoading?: any;
  setDataLoading?: any;
  nameData?: any;
  setNameData?: any;
  locationData?: any;
  setLocationData?: any;
  statusData?: any;
  setStatusData?: any;
  jobData?: any;
  setJobData?: any;
  experienceData?: any;
  setExperienceData?: any;
  nameFilters?: any;
  setNameFilters?: any;
  locationFilters?: any;
  setLocationFilters?: any;
  statusFilters?: any;
  setStatusFilters?: any;
  jobFilters?: any;
  setJobFilters?: any;
  experienceFilters?: any;
  setExperienceFilters?: any;
  bookmarkedEnabled?: any;
  setBookmarkedEnabled?: any;
  anchorEl?: any;
  onApply?: any;
  removeFilter?: any;
  onClearAllFilters?: any;

  isTesting?: boolean;
}

const FiltersPopup: FC<FiltersPopupProps> = (props) => {
  useEffect(() => {
    if (props.isTesting) {
      getUpdatedFilters();
    }
  }, []);

  useEffect(() => {
    if (!props.isTesting) {
      getUpdatedFilters();
    }
  }, [props.searchText]);

  const getUpdatedFilters = () => {
    let nameSearch = undefined;
    let locationSearch = undefined;
    let statusSearch = undefined;
    let jobSearch = undefined;
    let experienceSearch = undefined;

    if (
      props.selectedFilter === ENUMS.FILTERS.NAME &&
      props.searchText !== ""
    ) {
      nameSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.FILTERS.LOCATION &&
      props.searchText !== ""
    ) {
      locationSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.FILTERS.STATUS &&
      props.searchText !== ""
    ) {
      statusSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.FILTERS.JOB_ID &&
      props.searchText !== ""
    ) {
      jobSearch = props.searchText;
    } else if (
      props.selectedFilter === ENUMS.FILTERS.EXPERIENCE &&
      props.searchText !== ""
    ) {
      experienceSearch = props.searchText;
    }

    const payload = {
      NameSearch: nameSearch,
      LocationSearch: locationSearch,
      StatusSearch: statusSearch,
      JobSearch: jobSearch,
      ExperienceSearch: experienceSearch,
    };

    props.setDataLoading(true);
    API.getFiltersForApplications(payload)
      .then((response: any) => {
        props.setDataLoading(false);

        props.setNameData(response.entity?.nameFilters);
        props.setLocationData(response.entity?.locationFilters);
        props.setStatusData(response.entity?.statusFilters);
        props.setJobData(response.entity?.jobFilters);
        props.setExperienceData(response.entity?.experiencesFilters);
      })
      .catch((e) => {
        props.setDataLoading(false);
      });
  };

  const resetAll = () => {
    props.setNameFilters([]);
    props.setLocationFilters([]);
    props.setStatusFilters([]);
    props.setJobFilters([]);
    props.setExperienceFilters([]);
    props.setBookmarkedEnabled(false);
  };

  const resetFilterForType = () => {
    if (props.selectedFilter === ENUMS.FILTERS.NAME) {
      props.setNameFilters([]);
    } else if (props.selectedFilter === ENUMS.FILTERS.LOCATION) {
      props.setLocationFilters([]);
    } else if (props.selectedFilter === ENUMS.FILTERS.STATUS) {
      props.setStatusFilters([]);
    } else if (props.selectedFilter === ENUMS.FILTERS.EXPERIENCE) {
      props.setExperienceFilters([]);
    } else if (props.selectedFilter === ENUMS.FILTERS.JOB_ID) {
      props.setJobFilters([]);
    }
  };

  const id = props.isExpanded ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={props.isExpanded}
      anchorEl={props.anchorEl}
      onClose={() => props.setIsExpanded(false)}
    >
      <Box
        data-testid="filters-popup"
        sx={{
          width: 370,
          top: 160,
          left: 100,
          zIndex: 10,
          backgroundColor: Colors.White,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            height: 60,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pl: 2,
          }}
        >
          <Text fontWeight={700} fontSize={16}>
            Filters
          </Text>
          <IconButton
            data-testid="close-modal-btn"
            onClick={() => props.setIsExpanded(false)}
          >
            <IoClose />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "45%",
              height: 300,
              backgroundColor: Colors.Grey11,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 0.5,
              }}
            >
              <Switch
                data-testid="bookmark-enable-switch"
                checked={props.bookmarkedEnabled}
                onChange={() =>
                  props.setBookmarkedEnabled(!props.bookmarkedEnabled)
                }
                sx={{
                  "&.MuiSwitch-root .Mui-checked": {
                    color: Colors.Blue7,
                  },
                }}
              />
              <Text>Bookmarked</Text>
            </Box>
            <FilterName
              isActive={props.selectedFilter === ENUMS.FILTERS.NAME}
              title="Name"
              onClick={() => props.setSelectedFilter(ENUMS.FILTERS.NAME)}
              testId="name-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.FILTERS.JOB_ID}
              title="Job ID"
              onClick={() => props.setSelectedFilter(ENUMS.FILTERS.JOB_ID)}
              testId="job-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.FILTERS.LOCATION}
              title="Location"
              onClick={() => props.setSelectedFilter(ENUMS.FILTERS.LOCATION)}
              testId="location-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.FILTERS.STATUS}
              title="Status"
              onClick={() => props.setSelectedFilter(ENUMS.FILTERS.STATUS)}
              testId="status-filter-btn"
            />
            <FilterName
              isActive={props.selectedFilter === ENUMS.FILTERS.EXPERIENCE}
              title="Experience"
              onClick={() => props.setSelectedFilter(ENUMS.FILTERS.EXPERIENCE)}
              testId="experience-filter-btn"
            />
          </Box>
          <Box
            sx={{
              width: "55%",
              height: 300,
              backgroundColor: Colors.White,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search"
                sx={{
                  paddingRight: 0,
                  fontFamily: "Roboto",
                  backgroundColor: Colors.White,
                  "& .MuiInputBase-root": { height: "34px" },
                }}
                inputProps={{
                  "data-testid": "filter-search-input",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IoMdSearch size={20} />
                    </InputAdornment>
                  ),
                }}
                value={props.searchText}
                onChange={(ev) => {
                  props.setSearchText(ev.target.value);
                }}
              />
              <IconButton
                data-testid="search-empty-btn"
                onClick={() => resetFilterForType()}
                sx={{
                  height: 32,
                  width: 32,
                  // mt: '4px',
                  borderRadius: 0.5,
                  border: "1px solid " + Colors.Grey10,
                }}
              >
                <GrPowerReset />
              </IconButton>
            </Box>
            <Box
              sx={{
                // border: 2,
                overflowY: "auto",
                height: 260,
              }}
            >
              {!props.dataLoading &&
                props.selectedFilter === ENUMS.FILTERS.NAME &&
                props.nameData.map((item: any, index: number) => (
                  <CheckboxLabel
                    key={item}
                    checkBoxTestId="name-checkbox"
                    checked={props.nameFilters.includes(item)}
                    onChange={() => {
                      if (props.nameFilters.includes(item)) {
                        const modifiedArr = props.nameFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setNameFilters([...modifiedArr]);
                      } else {
                        props.setNameFilters([...props.nameFilters, item]);
                      }
                    }}
                    title={String(item)}
                  />
                ))}

              {!props.dataLoading &&
                props.selectedFilter === ENUMS.FILTERS.LOCATION &&
                props.locationData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    checkBoxTestId="location-checkbox"
                    checked={props.locationFilters.includes(item)}
                    onChange={() => {
                      if (props.locationFilters.includes(item)) {
                        const modifiedArr = props.locationFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setLocationFilters([...modifiedArr]);
                      } else {
                        props.setLocationFilters([
                          ...props.locationFilters,
                          item,
                        ]);
                      }
                    }}
                    title={String(item)}
                  />
                ))}

              {!props.dataLoading &&
                props.selectedFilter === ENUMS.FILTERS.STATUS &&
                props.statusData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    checkBoxTestId="status-checkbox"
                    checked={props.statusFilters.includes(item)}
                    onChange={() => {
                      if (props.statusFilters.includes(item)) {
                        const modifiedArr = props.statusFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setStatusFilters([...modifiedArr]);
                      } else {
                        props.setStatusFilters([...props.statusFilters, item]);
                      }
                    }}
                    title={String(item)}
                  />
                ))}

              {!props.dataLoading &&
                props.selectedFilter === ENUMS.FILTERS.JOB_ID &&
                props.jobData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    checkBoxTestId="job-checkbox"
                    checked={props.jobFilters.includes(item)}
                    onChange={() => {
                      if (props.jobFilters.includes(item)) {
                        const modifiedArr = props.jobFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setJobFilters([...modifiedArr]);
                      } else {
                        props.setJobFilters([...props.jobFilters, item]);
                      }
                    }}
                    title={String(item)}
                  />
                ))}

              {!props.dataLoading &&
                props.selectedFilter === ENUMS.FILTERS.EXPERIENCE &&
                props.experienceData.map((item: any) => (
                  <CheckboxLabel
                    key={item}
                    checkBoxTestId="experience-checkbox"
                    checked={props.experienceFilters.includes(item)}
                    onChange={() => {
                      if (props.experienceFilters.includes(item)) {
                        const modifiedArr = props.experienceFilters.filter(
                          (itemChild: any) => itemChild !== item
                        );
                        props.setExperienceFilters([...modifiedArr]);
                      } else {
                        props.setExperienceFilters([
                          ...props.experienceFilters,
                          item,
                        ]);
                      }
                    }}
                    title={Functions.ValueRetrieve.getYearTitle(item)}
                  />
                ))}

              {!props.dataLoading &&
                ((props.selectedFilter === ENUMS.FILTERS.NAME &&
                  props.nameData.length === 0) ||
                  (props.selectedFilter === ENUMS.FILTERS.EXPERIENCE &&
                    props.experienceData.length === 0) ||
                  (props.selectedFilter === ENUMS.FILTERS.JOB_ID &&
                    props.jobData.length === 0) ||
                  (props.selectedFilter === ENUMS.FILTERS.LOCATION &&
                    props.locationData.length === 0) ||
                  (props.selectedFilter === ENUMS.FILTERS.STATUS &&
                    props.statusData.length === 0)) && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 8,
                    }}
                  >
                    <Text>No Data</Text>
                  </Box>
                )}
              {props.dataLoading && (
                <Box
                  sx={{
                    height: "100%",
                    justifyContent: "center",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Oval
                    height={40}
                    width={40}
                    color={Colors.Blue7}
                    secondaryColor={Colors.LightBlue1}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: 60,
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            px: 2,
            gap: 2,
          }}
        >
          <ButtonOutlined
            testId="reset-all-button"
            onClick={resetAll}
            title="Reset All"
          />
          <ButtonFilled title="Apply" onClick={props.onApply} />
        </Box>
      </Box>
    </Popover>
  );
};

export default FiltersPopup;