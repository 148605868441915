// React Imports
import { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// UI Imports
import {
  Box,
  Dialog,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import Colors from "../../utils/Colors";
import Paths from "../../routes/Paths";
import STRINGS from "../../utils/Strings";
import Tabsection from "./Tabsection";
import { FaRegBookmark } from "react-icons/fa6";
import { IoMdShare } from "react-icons/io";
import Loader from "../../common/Loader";
import API from "../../api/API";
import Toasts from "../../utils/Toasts";
// Component Imports
import Breadcrumbs from "../../common/Breadcrumbs";
import Text from "../../common/Text";
import { IoMdSearch } from "react-icons/io";
import ButtonFilled from "../../common/ButtonFilled";
import Functions from "../../utils/Functions";
import BlueButton from "../../common/BlueButton";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaBookmark } from "react-icons/fa";
import StatusChip from "../Applications/StatusChip";
import HirePopup from "../Applications/HirePopup";
import RejectPopup from "../Applications/RejectPopup";
import SharePopup from "../Applications/SharePopup";
import ProposeTimeSlots from "../ProposeTimeSlots";
import ResumePopup from "../Applications/ResumePopup";

// Functional Imports

// Component Imports

interface ViewapplicationProps {
  isTesting?: boolean;
  testAppData?: any;
}

const Viewapplication: FC<ViewapplicationProps> = (props) => {
  const Params = useParams();
  const appId = Params.appid;
  const [appData, setAppData] = useState<any>({});

  const [loading, setLoading] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [shareDetails, setShareDetails] = useState();
  const [updateDataAgain, setUpdateDataAgain] = useState(false);
  const [hireOpen, setHireOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [isProposeSlotOpen, setIsProposeSlotOpen] = useState(false);
  const [showhire, setShowhire] = useState(false);
  const [showreject, setShowreject] = useState(false);
  const [showInterview, setShowInterview] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);
  const [slotId, setSlotId] = useState<string[]>([]);
  const [description, setDescription] = useState<string>("");
  const [resumeOpen, setResumeOpen] = useState(false);
  const [resumeLink, setResumeLink] = useState("");
  const [submissionID, setSubmissionId] = useState("");


  useEffect(() => {
    if (props.isTesting) {
      setAppData(props.testAppData);
      initialiseData();
      onShare();
      bookmarkAction();
      onHire();
      onReject();
      checkAvailabilities();
      handleScheduleClick();
      onResumeOpen({});
      onDownload();
      handleCloseProposeSlot();
      handleRefresh();
      checkForStatus("Interview Requested");
      checkForStatus("Pending Review");
      checkForStatus("Else");
    }
  }, []);

  useEffect(() => {
    initialiseData();
  }, [appId, updateDataAgain]);

  const initialiseData = () => {
    setLoading(true);
    API.getApplicationById(appId)
      .then((response) => {
        if (response?.status === 200) {
          setAppData(response?.entity);
          setIsBookmarked(response?.entity?.bookMarkDetails?.length > 0);
          checkForStatus(response?.entity?.resumeVLNStatus);
          setSubmissionId(response.entity.id);
        }
        setLoading(false);
      })
      .catch((e) => {
        Toasts.error(e);
        setAppData({});
        setLoading(false);
      });
  };

  const onShare = () => {
    setShareDetails(appData);
    setShareOpen(true);
  };

  const onHire = () => {
    setShareDetails(appData);
    setHireOpen(true);
  };

  const onReject = () => {
    setShareDetails(appData);
    setRejectOpen(true);
  };
  const onResumeOpen = (link: any) => {
    setResumeOpen(true);
    setResumeLink(link);
  };

  const onDownload = () => {
    const isDoc = appData.resumeFile?.includes("doc");

    API.downloadResume(Number(appData?.candidateDocId))
      .then((response) => {
        let linkToPdf = response.entity;

        linkToPdf = linkToPdf.replace("http", "https");
        
        if (isDoc) {
          Functions.Download.fileDownload(linkToPdf, appData.resumeFile);
        } else {
          onResumeOpen(linkToPdf);
        }
      })
      .catch((e) => {});
  };

  const bookmarkAction = () => {
    setLoading(true);
    if (isBookmarked) {
      API.debookmarkSubmissions(appData?.id).then((response) => {
        setIsBookmarked(false);
        setLoading(false);
      });
    } else {
      API.bookmarkSubmissions(appData.id).then((response) => {
        setIsBookmarked(true);
        setLoading(false);
      });
    }
  };

  const handleScheduleClick = () => {
    if (appData.resumeVLNStatus === "Interview Requested" || appData.resumeVLNStatus === "Interview Scheduled") {
      const activeAvailabilities = appData.interviewAvailabilities.filter(
        (availability: any) => availability.isActive
      );
      const slotId = appData.interviewAvailabilities.filter(
        (availability: any) => availability.id
      );
      if (activeAvailabilities.length > 0) {
        setAvailabilities(activeAvailabilities[0].currentAvailabilities);
        setSlotId(slotId);
        setDescription(activeAvailabilities[0].description);
      }
    }
    setIsProposeSlotOpen(true);
  };

  const handleCloseProposeSlot = () => {
    setIsProposeSlotOpen(false);
  };

  const handleRefresh = () => {
    setUpdateDataAgain(!updateDataAgain);
  };

  useEffect(() => {
    checkAvailabilities();
  }, [appData.interviewAvailabilities, appData.resumeVLNStatus]);

  const checkAvailabilities = () => {
    if (
      appData.interviewAvailabilities &&
      appData.resumeVLNStatus === "Interview Requested" || appData.resumeVLNStatus === "Interview Scheduled"
    ) {
      const activeAvailabilities = appData.interviewAvailabilities.filter(
        (availability: any) => availability.isActive
      );
      const slotId = appData.interviewAvailabilities.filter(
        (availability: any) => availability.id
      );
      if (activeAvailabilities.length > 0) {
        setAvailabilities(activeAvailabilities[0].currentAvailabilities);
        setSlotId(slotId);
        setDescription(activeAvailabilities[0].description);
      }
    }
  };

  const checkForStatus = (resumeVLNStatus: string) => {
    if (
      resumeVLNStatus === "Interview Requested" ||
      resumeVLNStatus === "Interview Scheduled"
    ) {
      setShowreject(true);
      setShowhire(true);
      setShowInterview(true);
    } else if (resumeVLNStatus === "Pending Review") {
      setShowreject(true);
      setShowInterview(true);
    } else {
      setShowreject(false);
      setShowhire(false);
      setShowInterview(false);
    }
  };

  console.log("initialSlots in Viewapplication:", availabilities);

  return (
    <Box
      data-testid="view-application-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "scroll",
      }}
    >
      {loading && <Loader />}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 1,
            pb: 1,
          }}
          path={[
            { path: Paths.APP + Paths.APPLICATIONS, name: "Home" },
            { path: Paths.APP + Paths.APPLICATIONS, name: "Applications" },
            {
              name:
                "Application ID " + (appData.resumeId ? appData.resumeId : ""),
            },
          ]}
        />
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search"
          sx={{
            paddingRight: 0,
            marginRight: 1.5,
            width: 300,
            fontFamily: "Roboto",
            backgroundColor: Colors.White,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IoMdSearch size={20} />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        sx={{
          ml: 4,
          width: "95%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          mb: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Text fontWeight={600} fontSize={20}>
            Details
          </Text>
          <Tooltip
            title={"Bookmark"}
            placement="top"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton onClick={bookmarkAction}>
              {isBookmarked && <FaBookmark size={18} color={Colors.Blue7} />}
              {!isBookmarked && (
                <FaRegBookmark size={18} color={Colors.Black1} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip
            title={"Share"}
            placement="top"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton onClick={onShare}>
              <IoMdShare size={18} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          {showInterview && (
            <ButtonFilled
              sx={{
                width: 170,
              }}
              title="Schedule Interview"
              onClick={handleScheduleClick}
            />
          )}
          {showreject && (
            <ButtonFilled
              sx={{
                width: 80,
              }}
              title="Reject"
              onClick={onReject}
            />
          )}
          {showhire && (
            <ButtonFilled
              sx={{
                width: 80,
              }}
              title="Hire"
              onClick={onHire}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: Colors.White,
          ml: 4,
          mr: 4,
          p: 2,
          pl: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {"Applicant’s name "}
            </Text>
            <Text fontWeight={700} fontSize={14}>
              {appData.candidateName}
            </Text>
          </Box>
          <Box
            sx={{
              width: "15%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {"Job ID"}
            </Text>
            <Text fontWeight={700} fontSize={14}>
              {appData.jobReference}
            </Text>
          </Box>
          <Box
            sx={{
              width: "20%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {"Job Title"}
            </Text>
            <Text fontWeight={700} fontSize={14}>
              {appData.jobTitle}
            </Text>
          </Box>
          <Box
            sx={{
              width: "20%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {STRINGS.COMMONSTR.STATUS}
            </Text>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flax-start",
                alignItems: "center",
                p: 0,
              }}
            >
              <StatusChip type={appData?.resumeVLNStatus} />
            </Box>
          </Box>
          <Box
            sx={{
              width: "20%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {"Hiring manager"}
            </Text>
            <Text fontWeight={700} fontSize={14}>
              {appData.hiringManagerName}
            </Text>
          </Box>
          <Box
            sx={{
              width: "17%",
              mr: 2,
            }}
          >
            <Text fontWeight={400} fontSize={12}>
              {"Resume"}
            </Text>
            <Tooltip
              title={"View Resume"}
              placement="right"
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
            >
              <IconButton onClick={onDownload}>
                <IoDocumentTextOutline size={20} color={Colors.Black1} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          p: 2,
          pl: 4,
          pt: 0,
        }}
      >
        <Tabsection 
          appData={appData} 
          submissionID={submissionID} 
          candidateName={appData.candidateName} 
          candidateEmail = {appData.email}
          initialSlots={
            appData.resumeVLNStatus === "Interview Requested" || appData.resumeVLNStatus === "Interview Scheduled"
              ? availabilities
              : []
          }
          setAvailabilities={setAvailabilities}
          modifyMode={appData.resumeVLNStatus === "Interview Requested" || appData.resumeVLNStatus === "Interview Scheduled"}
          slotId={slotId}
          resumeVLNStatus={appData.resumeVLNStatus}
          updateDataAgain={updateDataAgain}
          setUpdateDataAgain={setUpdateDataAgain}
          description={description}
          />
      </Box>
      <HirePopup
        open={hireOpen}
        setOpen={setHireOpen}
        applicationDetails={shareDetails}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
      <RejectPopup
        open={rejectOpen}
        setOpen={setRejectOpen}
        applicationDetails={shareDetails}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
      <SharePopup
        open={shareOpen}
        setOpen={setShareOpen}
        shareDetails={shareDetails}
      />
      <ResumePopup
        open={resumeOpen}
        setOpen={setResumeOpen}
        resumeLink={resumeLink}
      />
      <Modal open={isProposeSlotOpen} onClose={handleCloseProposeSlot}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Box
            sx={{
              bgcolor: "white",
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
            }}
          >
            <ProposeTimeSlots
              onCancel={handleCloseProposeSlot}
              submissionID={appData.id}
              candidateName={appData.candidateName}
              candidateEmail={appData.email}
              initialSlots={
                appData.resumeVLNStatus === "Interview Requested" || appData.resumeVLNStatus === "Interview Scheduled"
                  ? availabilities
                  : []
              }
              modifyMode={appData.resumeVLNStatus === "Interview Requested" || appData.resumeVLNStatus === "Interview Scheduled"}
              slotId={slotId}
              description={description}
              onRefresh={handleRefresh}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Viewapplication;
