// React Imports
import { FC, useState } from 'react';

// UI Imports
import { Box } from '@mui/material'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Appdetail from './Appdetail';
import InterviewDetail from './InterviewDetail';

// Functional Imports

// Component Imports

interface TabsectionProps {
  appData?: any;
  skilldata?: any;
  submissionID?:any;
  candidateName?: any;
  candidateEmail?: any;
  initialSlots?: any;
  setAvailabilities?: any;
  slotId?:any;
  resumeVLNStatus?: any;
  updateDataAgain? : any;
  setUpdateDataAgain? :any;
  modifyMode? : any;
  description?: any;
}

const Tabsection: FC<TabsectionProps> = (props) => {
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  return (
    <Box sx={{ width: '100%', mt: 2 }} className='editdetailtab'>
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange}>
            <Tab label="Application Details" value="1" />
            <Tab label="Interviews" value="2" />
          </TabList>
        </Box>

        <TabPanel sx={{ background: '#fff', mr: 2 }} value="1">
          <Appdetail appData={props.appData} />
        </TabPanel>
        <TabPanel sx={{ background: '#fff', mr: 2 }} value="2">
          <InterviewDetail 
            interviewSubmissionID={props.submissionID} 
            candidateName={props.candidateName} 
            candidateEmail={props.candidateEmail}
            initialSlots={props.initialSlots}
            setAvailabilities={props.setAvailabilities}
            slotId={props.slotId}
            resumeVLNStatus={props.resumeVLNStatus}
            updateDataAgain={props.updateDataAgain}
            setUpdateDataAgain={props.setUpdateDataAgain}
            modifyMode={props.modifyMode}
            description={props.description}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Tabsection;