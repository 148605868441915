import ActionTypes from "../action";

const initialState = {
  pagination: {
    pageSize: 10,
    pageIndex: 0,
  },
  sortModel: [],
  nameData: [],
  locationData: [],
  statusData: [],
  jobData: [],
  experienceData: [],
  nameFilters: [],
  locationFilters: [],
  statusFilters: [],
  jobFilters: [],
  experienceFilters: [],
  bookmarkedEnabled: false,
  showActiveJobs: false,
};

const applicationFilterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.UPDATE_APPLICATION_STATE:
      return action.payload;
    default:
      return state;
  }
};

export default applicationFilterReducer;
