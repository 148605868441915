// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, IconButton, Popover, Tooltip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import HoverActions from "./HoverActions";
import API from "../../api/API";
import Functions from "../../utils/Functions";

interface ActionTileApplicationProps extends Partial<GridRenderCellParams> {
  updateDataAgain?: boolean;
  setUpdateDataAgain?: any;
  onShare?: any;
  onHire?: any;
  onReject?: any;
  onResumeOpen?: any;
  onScheduleInterviewOpen?: any;

  hoverActionsEnabled?: boolean;
  isTesting?: boolean;
}

const ActionTileApplication: FC<ActionTileApplicationProps> = (props) => {
  const [hoverActionsEnabled, setHoverActionsEnabled] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isBookmark, setIsBookmark] = useState(false);

  useEffect(() => {
    if (props.isTesting) {
      handleClick({});
      bookmarkAction();
      onDownload();
      onHire();
      onShare();
      onReject();
      onScheduleInterviewOpen({});

      if (props.hoverActionsEnabled) {
        setHoverActionsEnabled(props.hoverActionsEnabled);
        handleClick({});
      }
    }
  }, []);

  useEffect(() => {
    const userId = Functions.LocalStorage.getString(
      Functions.LocalStorage.KEYS.USER_ID
    );

    let isBookmarkedPass = false;

    props.row?.bookMarkDetails?.map((item: any) => {
      if (Number(item.bookmarkedBy) === Number(userId)) {
        isBookmarkedPass = true;
      }
    });

    setIsBookmark(isBookmarkedPass);
  }, [props.row?.bookMarkDetails]);

  const handleClick = (event: any) => {
    if (hoverActionsEnabled) {
      setAnchorEl(null);
      setHoverActionsEnabled(false);
    } else {
      setAnchorEl(event.currentTarget);
      setHoverActionsEnabled(true);
    }
  };

  const id = hoverActionsEnabled ? "simple-popover" : undefined;

  const bookmarkAction = () => {
    if (isBookmark) {
      API.debookmarkSubmissions(props.row?.id).then((response) => {
        props.setUpdateDataAgain(!props.updateDataAgain);
      });
    } else {
      API.bookmarkSubmissions(props.row?.id).then((response) => {
        props.setUpdateDataAgain(!props.updateDataAgain);
      });
    }
  };

  const onDownload = () => {
    const isDoc = props.row?.resumeFile?.includes("doc");
    API.downloadResume(Number(props.row?.candidateDocId))
      .then((response) => {
        let linkToPdf = response.entity;

        linkToPdf = linkToPdf.replace("http", "https");

        if (isDoc) {
          Functions.Download.fileDownload(linkToPdf, props.row?.resumeFile);
        } else {
          props.onResumeOpen(linkToPdf);
        }
      })
      .catch((e) => {});
  };

  const onHire = () => {
    setHoverActionsEnabled(false);
    props.onHire(props.row);
  };

  const onShare = () => {
    setHoverActionsEnabled(false);
    props.onShare(props.row);
  };

  const onReject = () => {
    setHoverActionsEnabled(false);
    props.onReject(props.row);
  };

  const onScheduleInterviewOpen = (...params: any) => {
    setHoverActionsEnabled(false);
    props.onScheduleInterviewOpen(...params);
  };

  return (
    <Box
      data-testid="action-tile-application"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0,
      }}
    >
      <IconButton
        sx={{
          borderRadius: 2,
          backgroundColor: hoverActionsEnabled ? Colors.Grey12 : null,
        }}
        onClick={(ev) => handleClick(ev)}
      >
        <BsThreeDotsVertical size={20} color={Colors.Black1} />
      </IconButton>
      <Tooltip
        title={"Bookmark"}
        placement="top"
        arrow
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        <IconButton onClick={bookmarkAction}>
          {isBookmark && <FaBookmark size={18} color={Colors.Blue7} />}
          {!isBookmark && <FaRegBookmark size={18} color={Colors.Black1} />}
        </IconButton>
      </Tooltip>
      <Tooltip
        title="View Resume"
        placement="top"
        arrow
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        <IconButton onClick={onDownload}>
          <IoDocumentTextOutline size={20} color={Colors.Black1} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={hoverActionsEnabled}
        anchorEl={anchorEl}
        onClose={() => setHoverActionsEnabled(false)}
        anchorOrigin={{
          vertical: 40,
          horizontal: 10,
        }}
      >
        <HoverActions
          id={props.row?.id}
          isExpanded={hoverActionsEnabled}
          setIsExpanded={setHoverActionsEnabled}
          onShare={onShare}
          resumeVLNStatus={props.row?.resumeVLNStatus}
          candidateName={props.row?.candidateName}
          candidateEmail={props.row?.email}
          currentAvailabilities={props.row?.interviewAvailabilities}
          onHire={onHire}
          onReject={onReject}
          onScheduleInterviewOpen={onScheduleInterviewOpen}
          updateDataAgain={props.updateDataAgain}
          setUpdateDataAgain={props.setUpdateDataAgain}
        />
      </Popover>
    </Box>
  );
};

export default ActionTileApplication;
